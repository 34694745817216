<template>
       <v-main>
        <v-container
        class="fill-height"
        fluid
        >
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="12" lg="12" md="12">
            <h1 class="display-1 adminP">Panel de Administración de dominios</h1>

            <v-card-subtitle>Desde este sitio podrás realizar las siguientes acciones:</v-card-subtitle>
      
              <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-subtitle>1. Configurar tus DNS y Ip</v-list-item-subtitle>
                <v-list-item-subtitle>
                 2. Dar de Baja a tus dominios
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  3. Registrar contactos técnicos y de pago
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-col> 
      </v-row>
    </v-container>
    </v-main> 
   
</template>
<script>
export default {
    name: 'DashboardIndex',
    data: () => ({
        expandOnHover: false,
    })
}
</script>